
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { PaginationCommand } from '@/helpers/CommandHelper';
import { BuildPaginationModel } from '@/Builders/builders';
import { SearchRequestModel } from '../store/models/OrderlineModel';
@Component
export default class J1LPagination extends Vue {
  @Getter('getPaginationStatus', { namespace: 'orderlines' })
  isLastPage!: boolean;
  @Getter('doesCustomerHaveItems', { namespace: 'items' })
  doesCustomerHaveItems!: boolean;
  @Getter('doesCustomerHaveItemDetailsLoaded', { namespace: 'items' })
  doesCustomerHaveItemDetailsLoaded!: boolean;
  /* Properties */
  @Prop()
  TypeName!: string;
  @Prop()
  isLoading!: boolean;
  @Prop() searched!: any;
  @Prop() shouldStopLoadingMore!: boolean;
  /* Store Actions */

  /* Watchers */
  @Watch('page')
  onPageChange() {
    if (!this.isLoading) {
      this.setCommand();
    }
  }
  @Watch('isLoading')
  onIsLoadingChanged(val: boolean) {
    if (
      !val &&
      this.doesCustomerHaveItems &&
      !this.doesCustomerHaveItemDetailsLoaded &&
      this.TypeName == 'items'
    ) {
      this.setCommand();
    }
  }
  /* Data */
  page: number = 0;
  requestedPage: number = 0;
  length: number = 10;
  /* Methods */
  setCommand(shouldForce: boolean = false) {
    if ((!this.shouldStopLoadingMore || shouldForce) && this.page > this.requestedPage) {
      PaginationCommand(
        this.TypeName,
        this.$store,
        BuildPaginationModel(this.page, this.searched)
      );
      this.requestedPage = this.page;
    }
  }
  handleScroll() {
    let hasScrolled =
      window.scrollY > 0 &&
      window.innerHeight + window.scrollY >= document.body.offsetHeight * 0.9;
    let canPageLoad = !this.isLastPage && !this.isLoading;
    if (hasScrolled && canPageLoad && !this.shouldStopLoadingMore) {
      this.page++;
    }
  }
  refreshPage() {
    this.requestedPage = 0;
    this.setCommand(true);
  }
  /* Loaders */
  /* Mounted */
  mounted() {}
  /* Created */
  created() {
    this.page = this.$store.getters[`${this.TypeName}/getCurrentPage`];
    window.addEventListener('scroll', this.handleScroll);
  }
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
