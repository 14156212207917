
import { SetSnackBar } from '@/helpers/SnackbarHelper';
import ItemService from '@/services/item-service';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class J1LFileUpload extends Vue {
  $refs!: {
    FileUpload: HTMLFormElement;
  };
  /* Properties */
  @Prop({ default: false }) shouldShowComingSoon!: any;
  /* Store Actions */
  /* Watchers */
  @Watch('file')
  async onFileUpload(val: any) {
    let that = this;
    this.hasError = false;
    if (val && val.name) {
      this.file = val;
      if (!this.originalFileName) this.originalFileName = this.file.name;
      this.imageLoading = true;
      this.itemSrc = '';
      let formData = new FormData();
      formData.append('files[]', val, val.name);
      this.isUploadingItemfile = true;
      try {
        const response = await ItemService.UploadItemFile(formData, {
          onUploadProgress: function(progressEvent: any) {
            that.uploadPercentage = Math.round(
              (parseInt(progressEvent.loaded) * 100) / parseInt(progressEvent.total)
            );
          }.bind(this)
        });
        this.imageLoading = false;
        this.tempFileName = response.data.fileName;
      } catch {
        this.hasError = true;
        this.imageLoading = false;
        this.itemSrc = '';
        SetSnackBar('Something went wrong updating your file');
        return;
      }
      this.isUploadingItemfile = false;
      this.$emit('fileUploaded', this.file);
      var reader = new FileReader();
      reader.onload = e => {
        if (reader && reader.result) {
          this.itemSrc = reader.result.toString();
          this.imageLoading = false;
        }
      };
      reader.readAsDataURL(val);
      this.$emit('setShouldItemRefreshToTrue', true);
    }
  }
  /* Data */
  isLoading: boolean = false;
  dialog: boolean = false;
  imageLoading: boolean = false;
  tempFileName: string = '';
  isUploadingItemfile: boolean = false;
  isBringingUpFileDisplay: boolean = false;
  file: any = null;
  itemSrc: string = '';
  originalFileName: string = '';
  uploadPercentage = 0;
  hasError: boolean = false;
  containerId: string = `dialog-container${Math.random()}`;
  /* Computed */
  canSaveItem() {
    return !this.isUploadingItemfile && !this.imageLoading;
  }
  _file() {
    return this.file;
  }
  _tempFileName() {
    return this.tempFileName;
  }
  _originalFileName() {
    return this.originalFileName;
  }
  /* Async Functions */
  /* Utility Functions */
  setFileData(file: any) {
    this.file = file;
  }
  onFileUploadClick() {
    this.originalFileName = '';
    this.tempFileName = '';
    this.$refs.FileUpload.$refs.input.click();
  }
  clearData() {
    this.file = null;
    this.itemSrc = '';
    this.tempFileName = '';
    this.originalFileName = '';
    this.imageLoading = false;
    this.uploadPercentage = 0;
  }
  setUpDropzone() {
    var drop = document.getElementById(this.containerId)!;
    drop.addEventListener('dragover', change, false);
    drop.addEventListener('dragleave', change_back, false);
    drop.addEventListener('drop', change_back, false);

    function change() {
      drop.style.backgroundColor = 'rgb(32,34,245)';
    }

    function change_back() {
      drop.style.backgroundColor = 'transparent';
    }
  }
  /* Loaders */
  /* Mounted */
  mounted() {
    setTimeout(() => {
      this.setUpDropzone();
    }, 100);
  }
  /* Created */
  /* Emmited Functions */
}
