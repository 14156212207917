
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
@Component
export default class extends Vue {
  $refs!: {
    SearchInput: HTMLFormElement;
  };
  /* Properties */
  @Prop({ required: true }) items!: any;
  @Prop() itemText!: string;
  @Prop() itemValue!: any;
  @Prop() minTextLength!: number;
  @Prop() subText!: string;
  /* Store Actions */
  /* Watchers */
  @Watch('searchValue')
  change(val: string = '', oldVal: string) {
    this.$emit('searchOrders', {
      value: this.searchValue
    });
  }
  @Watch('items')
  onItemsChange(val: any) {
    if (val) {
      if (this.searchValue) {
        this.displayedItems = val;
      } else {
        this.displayedItems = val.slice(0, 10);
      }
    }
  }
  /* Data */
  displayedItems: any = [];
  searchValue: string = '';
  itemDisplayText: string = '';
  item: number = 1;
  hoveredItem: any = null;
  isListItemFocused: boolean = false;
  shouldShowSearchList: boolean = false;
  valid: boolean = true;
  /* Utility Functions */
  onTextBoxFocus() {
    if (!this.items[0]) {
      this.$emit('searchOrders', {
        value: ''
      });
    }
    this.shouldShowSearchList = true;
  }
  onTextBoxBlur(event: any) {
    event.target.blur();
    setTimeout(() => {
      if (!this.isListItemFocused) {
        this.shouldShowSearchList = false;
      }
    }, 100);
  }
  onClear() {
    this.searchValue = '';
    this.setSearchValue('', false);
  }
  isVisible(item: any) {
    const itemText = this.itemText ? item[this.itemText] : item;
    return (
      !this.searchValue ||
      (itemText &&
        itemText.toLocaleLowerCase().indexOf(this.searchValue.toLocaleLowerCase()) >
          -1) ||
      (this.subText &&
        item[this.subText]
          .toLocaleLowerCase()
          .indexOf(this.searchValue.toLocaleLowerCase()) > -1)
    );
  }
  matchName(current: string, isSubtitle: boolean = false) {
    if (!new RegExp(/^[^<{}>\\]+$/).test(this.searchValue)) return current;
    let noSpecial = current.replace(
      this.searchValue,
      match =>
        `<span class="${isSubtitle ? 'roboto-bold' : 'roboto-regular'}">${match}</span>`
    );
    return noSpecial;
  }
  setSearchValue(value: any, isEnter: boolean) {
    this.$refs.SearchInput.blur();

    let itemVal = this.determineItemValue(value);
    if (!itemVal) {
      itemVal = value;
    }
    this.searchValue = itemVal;
    this.$emit('searchValueSet', { value: this.searchValue, isEnter });
  }

  determineItemValue(item: any): any {
    if (this.itemValue) return item[this.itemValue];
    else if (this.itemText) return item[this.itemText];
    else return item;
  }
  onListItemFocus() {
    this.isListItemFocused = true;
  }
  moveNext() {
    let group = document.getElementById('list-item-group');
    if (
      this.items[0] &&
      this.hoveredItem !== null &&
      this.hoveredItem + 1 < this.items.length
    ) {
      this.hoveredItem++;
      group!.scrollTop = (this.hoveredItem - 1) * 68;
    } else {
      this.hoveredItem = 0;
      group!.scrollTop = 0;
    }
  }
  movePrevious() {
    let group = document.getElementById('list-item-group');
    if (this.items[0] && this.hoveredItem !== null && this.hoveredItem - 1 >= 0) {
      group!.scrollTop = (this.hoveredItem - 2) * 68;
      this.hoveredItem--;
    } else {
      group!.scrollTop = (this.items.length - 1) * 68;
      this.hoveredItem = this.items.length - 1;
    }
  }

  /* Loaders */
  /* Mounted */
  mounted() {
    if (this.items[0]) this.displayedItems = this.items;
  }
}
